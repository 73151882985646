import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Container, Table } from 'react-bootstrap';

import Login from '../components/dashboard/login';
import Api from '../lib/Api';
import MinimalLayout from "../components/layout/minimal";
import SEO from "../components/seo";
import styles from '../components/styles/dashboard.module.scss';
import Logo from '../images/logo.png';

const AmtDashboardPage = () => {
    const [token, setToken] = useState(null);
    const [users, setUsers] = useState([]);
    
    const getUsers = async () => {
        try {
            const users = await Api.getAmtDashboardUsers(token);
            setUsers(users);
        } catch (err) {
            if (err.statusCode === 403) {
                setToken(null);
            }

            window.alert(err.message);
        }
    };

    useEffect(() => {
        if (token) {
            getUsers();
        }
    }, [token]);

    return (
        <MinimalLayout>
            <SEO title="AMT Dashboard" />
            <main className={styles.main}>
                {token ? (
                    <Container className={styles.contentWrapper} style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                        <img className={styles.logo} src={Logo} alt="Covidseeker" />

                        <div className={styles.tableScrollable}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th scope="col">AMT Worker ID</th>
                                        <th scope="col">Donation Time</th>
                                        <th scope="col">Submitted Survey?</th>
                                        <th scope="col">Uploaded Data?</th>
                                        <th scope="col">Useful Data?</th>
                                        <th scope="col">Locations Logged</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user =>
                                        <tr key={user.amt_code}>
                                            <td>{user.amt_code}</td>
                                            <td>{user.donation_time_formatted}</td>
                                            <td>{!!user.user_id ? <strong className="text-success">Yes</strong> : <span className="text-danger">No</span>}</td>
                                            <td>{!!user.has_data ? <strong className="text-success">Yes</strong> : <span className="text-danger">No</span>}</td>
                                            <td>{user.location_count > 0 ? <strong className="text-success">Yes</strong> : <span className="text-danger">No</span>}</td>
                                            <td>{user.location_count}</td>
                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        <p className="mt-4 text-muted text-center">Showing {users.length} record(s).</p>
                    </Container>
                ) : (
                    <Login setToken={token => setToken(token)} />
                )}
            </main>
        </MinimalLayout>
    );
};

export default AmtDashboardPage;
