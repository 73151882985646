import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';

import styles from '../styles/dashboard.module.scss';
import Logo from '../../images/logo.png';

export default ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        try {
            const authStr = window.btoa(username + ':' + password);
            setToken(authStr);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className={styles.loginWrapper}>
            <Form onSubmit={handleLogin}>
                <img src={Logo} alt="Covidseeker" />
                
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </Form.Group>
                <hr />
                <div className="text-center">
                    <Button variant="primary" type="submit">Submit</Button>
                </div>
            </Form>
        </div>
    );
}
